<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
      content-class="profile-card"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          light
          v-bind="attrs"
          v-on="on"
          icon
        >
          <v-avatar color="white">
            <img
              v-if="user.avatar"
              :src="user.avatar"
              :alt="[user.persona.nombre, user.persona.apellido].join(' ')"
            >

            <span v-else class="black--text headline">
              {{ user.persona.nombre[0] }}{{ user.persona.apellido[0] }}
            </span>
          </v-avatar>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar color="primary">
                <img
                  v-if="user.avatar"
                  :src="user.avatar"
                  :alt="user.persona.nombre"
                >

                <span v-else class="white--text headline">
              {{ user.persona.nombre[0] }}{{ user.persona.apellido[0] }}
            </span>
              </v-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ user.persona.fullname }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t('app.general.member_since') }} {{ $moment(user.created_at).fromNow() }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="openProfile"
          >
            {{ $t('app.buttons.edit') }} {{ $t('app.general.profile') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
  import {mapState} from 'vuex'

    export default {
        name: "ProfileBar",
      data: () => ({
        fav: true,
        menu: false,
        message: false,
        hints: true,
      }),
      computed: {
        ...mapState(['user']),
      },
      methods: {
        openProfile() {
          this.goTo('AdminUsuarioPerfil')
          this.menu = false
        },
      },
    }
</script>

<style scoped>
  .profile-card {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
</style>
